import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdClose } from "react-icons/md";
import "./Popup.css";

function Popup({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    age: "",
    additionalDetails: "",
  });

  const handleValidationErrors = () => {
    // Custom validation logic
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.email.trim() || !/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
    }

    if (
      !formData.age.trim() ||
      isNaN(parseInt(formData.age, 10)) ||
      parseInt(formData.age, 10) < 1
    ) {
      errors.age = "Invalid age";
    }

    // You can add more custom validation rules as needed

    return errors;
  };

  const handleSubmit = async () => {
    const validationErrors = handleValidationErrors();

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch("https://api.pinkpahel.in/user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const data = await response.json();
          checkoutHandler(formData.email);
        } else {
          const data = await response.json();
          toast.error(data.error);
        }
      } catch (error) {
        console.error("Error creating user:", error.message);
        toast.error("Please try again after some time");
      }
    } else {
      Object.values(validationErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  const checkoutHandler = (email) => {
    let key, order;
    const amount = 849;

    fetch(`https://api.pinkpahel.in/getkey/${email}`)
      .then((response) => response.json())
      .then((data) => {
        key = data.key;

        return fetch(`https://api.pinkpahel.in/checkout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount }),
        });
      })
      .then((response) => response.json())
      .then(async (data) => {
        const options = {
          key,
          amount: amount,
          currency: "INR",
          name: "PAHEL",
          order_id: data.order.id,
          callback_url: `https://api.pinkpahel.in/paymentverification`,
          prefill: {
            name: formData.name,
            email: formData.email,
            contact: formData.number,
          },
          notes: {},
          theme: {
            color: "#b23b57",
          },
        };

        // Pass the email to your backend when calling the checkout route
        const backendResponse = await fetch("https://api.pinkpahel.in/checkout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...options, email }),
        });

        const backendData = await backendResponse.json();

        const razorpay = new window.Razorpay(options);
        razorpay.on("payment.failed", function (response) {
          console.error("Payment failed:", response.error.description);
        });
        const value = await razorpay.open();
      })
      .catch((error) => {
        console.error("Error occurred during checkout:", error);
      });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup-form ">
      <div className="popup-form-content">
        <div className="popup-header">
          <div className="close-button " onClick={onClose}>
            <MdClose size={30} />
          </div>
          <p className="font-zilla font-500 popup-form-heading">Join Us</p>
        </div>
        <form
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <label htmlFor="name" className="font-zilla font-500">
            Name:
          </label>
          <input
            className="input font-zilla font-500"
            type="text"
            id="name"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />

          <label htmlFor="email" className="font-zilla font-500">
            Email:
          </label>
          <input
            className="input font-zilla font-500"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />

          <label htmlFor="phoneNumber" className="font-zilla font-500">
            Phone Number:
          </label>
          <input
            className="input font-zilla font-500"
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={(e) =>
              setFormData({ ...formData, phoneNumber: e.target.value })
            }
          />

          <label htmlFor="age" className="font-zilla font-500">
            Age:
          </label>
          <input
            className="input font-zilla font-500"
            type="number"
            id="age"
            name="age"
            min={18}
            placeholder="Age"
            value={formData.age}
            onChange={(e) => setFormData({ ...formData, age: e.target.value })}
          />

          <label htmlFor="additionalDetails" className="font-zilla font-500">
            Additional Details:
          </label>
          <textarea
            className="input textarea font-zilla font-500"
            id="additionalDetails"
            name="additionalDetails"
            placeholder="Type a message ..."
            value={formData.additionalDetails}
            onChange={(e) =>
              setFormData({ ...formData, additionalDetails: e.target.value })
            }
          ></textarea>

          <p className="font-zilla note font-500">
            Note : Amount of 849 will be charged as registration fees ! (If the
            payment fails, try again after a minute)
          </p>

          <button type="submit" className="round-btn-popup">
            Submit
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Popup;
