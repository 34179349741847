import React from "react";
import "./HomeMidSection.css";

import working from "../../../assets/images/working.png";
import empowerment from "../../../assets/images/empowerment.png";
import square from "../../../assets/images/square-bg.png";
import pinkLine from "../../../assets/images/rectangle-bg.png";
import greencheck from "../../../assets/images/greencheck.png";
import Card from "../../Card/Card";

const HomeMidSection = () => {
  return (
    <>
      <div className="min-info-container" id="featured">
        <div className="first-mid-info-container">
          <div className="left-mid-info-container">
            <img src={pinkLine} />
            <img src={empowerment} />
            <p className="font-zilla font-bold">
              Women Empowerment and Women Safety
            </p>
          </div>
          <div className="right-mid-info-container">
            <Card
              header="Safe and Reliable"
              description="The passengers will have the option to share their live location to their guardian along with the vehicle and driver details."
            />
            <Card
              header="Customer Satisfaction"
              description="Females would be able to book their transportation from where they are.They would be able to know the accurate cost of ride."
            />
            <Card
              header="Women Empowerment"
              description="We recruit and train a large number of women drivers, providing them with the necessary skills,knowledge and resources."
            />
          </div>
        </div>
        <img src={pinkLine} className="reverse-pink-line" id="working"/>
      </div>
      <div className="mid-bottom-container">
        <img src={square} className="mid-square-bg" />
        <div className="left-mid-bottom-info-container">
          <img src={working} />
        </div>
        <div className="right-mid-bottom-info-container">
          <img src={pinkLine} />
          <h2 className="font-zilla ">How PAHEL Works</h2>
          <p className="font-zilla font-500">
            As the service will be provided 24*7 to all the clause for security
            and saftey of female drivers also has been done by the end pahel.
          </p>
          <ul>
            <li>
              <img src={greencheck} />
              <p>
                Female drivers will be able to register themselves via
                application.
              </p>
            </li>
            <li>
              <img src={greencheck} />
              <p>
                Female Passengers will be able to book a ride via application.
              </p>
            </li>
            <li>
              <img src={greencheck} />
              <p>The main and unique feature of PAHEL is NO ASK NO GIVE.</p>
            </li>
            <li>
              <img src={greencheck} />
              <p>There will be the recharge system in application.</p>
            </li>
            <li>
              <img src={greencheck} />
              <p>
                There will corporate office of in every district for the better
                monitoring.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default HomeMidSection;
