import React, { useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import NotificationBanner from "./components/NotificationBanner/NotificationBanner";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Resources from "./pages/Resources/Resources";
import Teams from "./pages/Teams/Teams";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
import CancellationPolicy from "./pages/CancellationPolicy/CancellationPolicy";
import TermsConditions from "./pages/TermsCondition/TermsCondition";
import Shipping from "./pages/Shipping/Shipping";
import Pricing from "./pages/Pricing/Pricing";
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";

function App() {
  const [isFormOpen, setFormOpen] = useState(false);

  const openForm = () => {
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };
  return (
    <div className="App">
      <NotificationBanner />
      <Navbar openForm={openForm} />
      <Routes>
        <Route
          exact
          path="/"
          element={<Home isOpen={isFormOpen} onClose={closeForm} />}
        />
        <Route
          exact
          path="/resources"
          element={
            <Resources
              isOpen={isFormOpen}
              onClose={closeForm}
              openForm={openForm}
            />
          }
        />
        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route exact path="/our-team" element={<Teams />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/shipping" element={<Shipping />} />
        <Route exact path="/refund-policy" element={<RefundPolicy />} />
        <Route
          exact
          path="/cancellation-refund-policy"
          element={<CancellationPolicy />}
        />
        <Route exact path="/terms-conditions" element={<TermsConditions />} />
        <Route exact path="/pricing" element={<Pricing />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
