import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

import { ReactComponent as CloseMenu } from "../../assets/svgs/x.svg";
import { ReactComponent as MenuIcon } from "../../assets/svgs/menu.svg";

import PahelLogo from "../../assets/images/pahellogo.png";
import RoundBtn from "../RoundBtn/RoundBtn";
import helpline from "../../assets/images/helpline icon.png";

const Header = ({ openForm }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <div className="header">
      <div className="logo-nav">
        <div className="logo-container">
          <Link to="/" className="redirects">
            <img src={PahelLogo} className="logo" />
          </Link>
        </div>
        <div>
          <ul className={click ? "nav-options active" : "nav-options"}>
            <li
              className="option"
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <NavLink
                to="/"
                exact
                activeClassName="active-link"
                className="redirects"
              >
                Home
              </NavLink>
            </li>
            <li
              className="option"
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <a href="/#featured" className="redirects">
                Features
              </a>
            </li>
            <li
              className="option"
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <a href="/#working" className="redirects">
                Working
              </a>
            </li>
            <li
              className="option"
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <NavLink
                to="/our-team"
                exact
                activeClassName="active-link"
                className="redirects"
              >
                Our Team
              </NavLink>
            </li>
            <li
              className="option"
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <NavLink
                to="/resources"
                exact
                activeClassName="active-link"
                className="redirects"
              >
                Resources
              </NavLink>
            </li>
            <li
              className="option"
              onClick={() => {
                closeMobileMenu();
              }}
            >
              <a
                href="#footer"
                className="redirects"
                onClick={() => {
                  closeMobileMenu();
                }}
              >
                Contact
              </a>
            </li>
            <li className="option mobile-menu" onClick={closeMobileMenu}>
              <Link to="/" className="redirects">
                <RoundBtn
                  className="join-us-button"
                  name={"Join Us"}
                  clickFunc={openForm}
                />
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <ul className="nav-right option">
            <li className="joinus option" onClick={closeMobileMenu}>
              <Link to="/" className="redirects desktop-menu">
                <RoundBtn name={"Join Us"} clickFunc={openForm} />
              </Link>
            </li>
            <li onClick={closeMobileMenu} className="helpline-design">
              <img src={helpline} className="helpline-image"></img>
              <Link to="tel:+91 8368629913" className="phone-number-design">
                <div className="helpline-text desktop-menu">
                  Helpline Number<br></br>
                  <p className="phone-text">+91 8368629913</p>
                </div>{" "}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <CloseMenu className="menu-icon" />
        ) : (
          <MenuIcon className="menu-icon" />
        )}
      </div>
    </div>
  );
};

export default Header;
